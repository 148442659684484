body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
  text-decoration-color: currentColor;
  color: inherit;
}

@media print {
  a {
    text-decoration: none;
  }
}

* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important;                 /* Firefox 48 – 96 */
  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
